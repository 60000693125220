<template>
  <b-card title="Users List">
    <b-col cols="12 row text-left" class="p-0 mt-1">
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <router-link
          to="/create-users"
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary mb-2"
        >
          Create User
        </router-link>
      </b-col>
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal
        >
          Export Users
        </b-button>
      </b-col>
    </b-col>
    <b-row class="justify-content-between">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="operator">Operator:</label>
          <v-select
            id="operator"
            label="name"
            v-model="filter.operator"
            :options="option"
            placeholder="-- Pilih --"
            :reduce="(option) => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="group">Group:</label>
          <v-select
            id="group"
            label="description"
            v-model="filter.group"
            :options="groupList"
            placeholder="-- Pilih --"
            :reduce="(groupList) => groupList.name"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Name or Email"
            v-model="filter.search"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="referrer_code">Referrer Code:</label>
          <input
            id="referrer_code"
            type="text"
            class="form-control"
            placeholder="Referrer Code"
            v-model="filter.referrer_code"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-between">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">Start Date:</label>
          <flat-pickr
            id="start-date"
            v-model="filter.register_start_date"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">End Date:</label>
          <flat-pickr
            id="end-date"
            v-model="filter.register_end_date"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="last-active-start-date">Last Active Start Date:</label>
          <flat-pickr
            id="last-active-start-date"
            v-model="filter.last_active_start_date"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="last-active-end-date">Last Active End Date:</label>
          <flat-pickr
            id="last-active-end-date"
            v-model="filter.last_active_end_date"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true }"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="status">Status:</label>
          <v-select
            id="status"
            label="name"
            v-model="filter.status"
            :options="status"
            placeholder="-- Pilih --"
            :reduce="(status) => status.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="age">Age:</label>
          <v-select
            id="age"
            label="name"
            v-model="filter.age"
            :options="ages"
            placeholder="-- Pilih --"
            :reduce="(age) => age.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :userData="userData"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :current-page="currentPage"
    />

    <Modal
      :get-history="getHistory" 
      :get-columns="getColumns" 
      :history="histories" 
      :columns="columns" 
      :filterParams ="filter"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Table from "@/components/users/Table.vue";
import Modal from "@/components/users/Modal.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    flatPickr,
    Modal
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      userData: {},
      option: [
        { value: "indosat", name: "Indosat" },
        { value: "telkomsel", name: "Telkomsel" },
        { value: "xl", name: "XL" },
        { value: "smartfren", name: "Smartfren" },
        { value: "axis", name: "Axis" },
        { value: "three", name: "Tri (3)" },
        { value: "ceria", name: "Ceria" },
      ],
      status: [
        { value: "active", name: "Aktif" },
        { value: "inactive", name: "Tidak Aktif" },
      ],
      groupList: [],
      filter: {},
      validations: "",
      histories: [],
      columns: [],
      ages: [
        { value: "18-24", name: "18 sd 24" },
        { value: "25-34", name: "25 sd 34" },
        { value: "35-44", name: "35 sd 44" },
        { value: ">=45", name: ">= 45" },
      ],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.loadGroup();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/api/v1/admin/users", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.userData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadGroup() {
      this.$http.get("/api/v1/admin/users/groups/list").then((response) => {
        this.groupList = response.data.data;
      });
    },
    getHistory(){
      this.$http
        .get("/api/v1/admin/export/history/users")
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns(){
      this.$http
        .get("/api/v1/admin/export/column?type=users")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Users?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/users/remove/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Users successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
